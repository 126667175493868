import Script from "next/script";

type Props = {
  snippets: any[];
};

const Snippets = ({ snippets }: Props) => (
  <>
    {Array.isArray(snippets) &&
      snippets.map((s, i) => {
        if (s.src) {
          return (
            <Script
              key={i}
              src={s.src}
              strategy={s.strategy}
              async={s.isAsync ?? false}
            />
          );
        } else {
          return (
            <Script
              key={i}
              id={`snippet-${i}`}
              strategy={s.strategy}
              dangerouslySetInnerHTML={{ __html: s.body ?? "" }}
            />
          );
        }
      })}
  </>
);

export default Snippets;
